import '../css/login.css'
import { commonrequest as get } from '../services/ApiCall.js';
import { QrScanner } from '@yudiel/react-qr-scanner';
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import _debounce from 'lodash/debounce';

const Login = () => {
    const location = useLocation();
    const [modalPopUp, setModalPopUp] = useState(false)
    const [scanning, setScanning] = useState(true);
    const [code, setCode] = useState({
        code: '',
        phNumber: location.state?.phNum,
        userName: location.state?.userName
    });
    const [message, setMessage] = useState('');
    const [background, setBackground] = useState('');
    const [audioSuccess] = useState(new Audio('/assets/success.mp3'));

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('login') !== 'true') {
            window.location.href = '/login';
        }
    }, []);


    const handleCodeVerify =  useCallback(async () => {
        //setModalPopUp(false)
        try {
            const result = await get('POST', 'https://cellsteel.co/verify-coupon', JSON.stringify({ code}));
            if (result.data?.success) {
                setModalPopUp(true)
                setMessage('Success! Code is redeemed!');
                setBackground('green');
		//audioSuccess.play();
                                
            } else {
                setMessage(result.data?.error);
                setBackground(result.data?.colour);
                setModalPopUp(true) 
            }
        } catch (error) {
            console.error(error);        
        }
    }, [code]);

    const handleQRDecode = _debounce(async (result) => {
        if (scanning && !modalPopUp) {
            console.log('QR code scanned:', result);
            setScanning(false); // Stop further scanning
            setCode(prevCode => ({ ...prevCode, code: result }));
        }
    }, 50);

    useEffect(() => {
        if (code.code) {
            handleCodeVerify();
        }
    }, [code.code, handleCodeVerify]);

    const onHide = () => {
        setModalPopUp(false); 
	 if (message?.includes('Success! Code is redeemed')) {
            audioSuccess.play().catch(error => {
                console.error('Error playing audio:', error);
            });
         }
        setMessage('')
	setScanning(true);
    };


    return (
        <div className='container-login100'>
            <div className="wrap-login100">
                <div className='login100-form-title'>
                    < h4 style={{ opacity: 1 , color: background }}>{message}</h4>
                    <h5>Enter your coupon code below:</h5>
                    < input className='input100' type='text' placeholder='Enter 8 digit code'
                        value={code.code}
                        onChange={(e) => setCode({...code, code: e.target.value})} />
                    <button className='login100-form-btn' onClick={handleCodeVerify}> Check</button>

                    <QrScanner
                        onDecode={handleQRDecode}
                        onError={(error) => console.log(error?.message)}
                    />
                </div>
            </div>
            { modalPopUp &&
                <Modal
                    show={modalPopUp}
                    onHide={onHide}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5>Coupon{code?.code ? `(${code?.code})` : null}</h5>
                            <p></p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ color: 'black' }}>Thank you for using CellSteel.</div>
                        <h6 style={{ color: background }}>
                            {message && (
                                <>
                                    <br />
                                    {message}
                                    {message?.includes('Success! Code is redeemed') && (
                                        <>
                                            <br />
                                            <p>Take a screenshot for your reference</p>
                                        </>
                                    )}
                                </>
                            )}
                        </h6>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onHide}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            }
        </div>
        
    );
};

export default Login;
