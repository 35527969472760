// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Flaticon.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Flaticon.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Flaticon.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Flaticon.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/Flaticon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#Flaticon" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `	/*
  	Flaticon icon font: Flaticon
  	Creation date: 27/07/2019 08:27
  	*/

@font-face {
  font-family: "Flaticon";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("embedded-opentype"),
       url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"),
       url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff"),
       url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype"),
       url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-left-arrow:before { content: "\\f100"; }
.flaticon-right-arrow:before { content: "\\f101"; }
.flaticon-play-button:before { content: "\\f102"; }
.flaticon-quote:before { content: "\\f103"; }
.flaticon-clock:before { content: "\\f104"; }
.flaticon-world:before { content: "\\f105"; }
.flaticon-mail:before { content: "\\f106"; }`, "",{"version":3,"sources":["webpack://./src/css/flaticon.css"],"names":[],"mappings":"CAAC;;;IAGG;;AAEJ;EACE,uBAAuB;EACvB,4CAAiC;EACjC;;;;4DAIwD;EACxD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE;IACE,uBAAuB;IACvB,0DAAwD;EAC1D;AACF;;AAEA;;EAEE,qBAAqB;AACvB,kBAAkB;AAClB;;AAEA,8BAA8B,gBAAgB,EAAE;AAChD,+BAA+B,gBAAgB,EAAE;AACjD,+BAA+B,gBAAgB,EAAE;AACjD,yBAAyB,gBAAgB,EAAE;AAC3C,yBAAyB,gBAAgB,EAAE;AAC3C,yBAAyB,gBAAgB,EAAE;AAC3C,wBAAwB,gBAAgB,EAAE","sourcesContent":["\t/*\n  \tFlaticon icon font: Flaticon\n  \tCreation date: 27/07/2019 08:27\n  \t*/\n\n@font-face {\n  font-family: \"Flaticon\";\n  src: url(\"../fonts/Flaticon.eot\");\n  src: url(\"../fonts/Flaticon.eot?#iefix\") format(\"embedded-opentype\"),\n       url(\"../fonts/Flaticon.woff2\") format(\"woff2\"),\n       url(\"../fonts/Flaticon.woff\") format(\"woff\"),\n       url(\"../fonts/Flaticon.ttf\") format(\"truetype\"),\n       url(\"../fonts/Flaticon.svg#Flaticon\") format(\"svg\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n@media screen and (-webkit-min-device-pixel-ratio:0) {\n  @font-face {\n    font-family: \"Flaticon\";\n    src: url(\"../fonts/Flaticon.svg#Flaticon\") format(\"svg\");\n  }\n}\n\n[class^=\"flaticon-\"]:before, [class*=\" flaticon-\"]:before,\n[class^=\"flaticon-\"]:after, [class*=\" flaticon-\"]:after {   \n  font-family: Flaticon;\nfont-style: normal;\n}\n\n.flaticon-left-arrow:before { content: \"\\f100\"; }\n.flaticon-right-arrow:before { content: \"\\f101\"; }\n.flaticon-play-button:before { content: \"\\f102\"; }\n.flaticon-quote:before { content: \"\\f103\"; }\n.flaticon-clock:before { content: \"\\f104\"; }\n.flaticon-world:before { content: \"\\f105\"; }\n.flaticon-mail:before { content: \"\\f106\"; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
