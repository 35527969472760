import '../css/login.css';
import React, { useState } from 'react';
import { commonrequest as get } from '../services/ApiCall.js';
import { useNavigate } from 'react-router-dom';


const Login = (props) => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    userName: '',
    phoneNumber: '',
    otp: '',
    message: '',
    otpSent: false,
    loading: false
  });

  const [audioBusiness] = useState(new Audio('/assets/business.mp3'));
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSendOTP = async () => {
    try {
      setUserData(prevState => ({
        ...prevState,
        loading: true
      }));

      const result =  await get('POST', `https://cellsteel.co/send-otp`, JSON.stringify({ to: userData.phoneNumber, name: userData.userName }));
      if (result.status === 200) {
        setUserData(prevState => ({
          ...prevState,
          message: `OTP sent to ${userData.phoneNumber}`,
          otpSent: true
        }));
      }

    } catch (error) {
      console.error(error);
      setUserData(prevState => ({
        ...prevState,
        message: 'Failed to send OTP'
      }));
    } finally {
      setUserData(prevState => ({
        ...prevState,
        loading: false
      }));
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const result = await get('POST', `https://cellsteel.co/verify-otp`, JSON.stringify({ to: userData.phoneNumber, code: userData.otp }));
      if (result.data.success) {
        navigate('/coupon?login=true', { state: { phNum: userData.phoneNumber, userName: userData.userName } });
        props.setIsLoggedIn(true)
        let playCount = 0

        const playAudio = () => {
          audioBusiness.play();
        };
  
        const audioEndedHandler = () => {
          playCount++;
          if (playCount < 2) {
            setTimeout(playAudio, 4000); // Play again after 4 seconds
          }
        };
  
        audioBusiness.addEventListener('ended', audioEndedHandler);
  
        // Initial play
        playAudio();
  
        // Cleanup function
        return () => {
          audioBusiness.removeEventListener('ended', audioEndedHandler);
        };;
      } 
      setUserData(prevState => ({
        ...prevState,
        message: 'Incorrect OTP'
        
      }));
    } catch (error) {
      console.error(error);
      setUserData(prevState => ({
        ...prevState,
        message: 'Failed to send OTP'
      }));
    }
  };
  

  return (
    <div className="container-login100">
      <div className="wrap-login100">
        <div className="login100-form validate-form">
          <div className="otp_title">
            <h3 className="login100-form-title">Login with OTP</h3>
            {userData.message && userData.otpSent ? (
              <div>{userData.message}</div>
            ) : null}
          </div>

          {userData.otpSent ? (
            <>
              <div>
                <label>Enter OTP:</label>
                <input
                  className="input100"
                  type="text"
                  placeholder="Enter OTP"
                  name="otp"
                  value={userData.otp}
                  onChange={handleChange}
                />
              </div>
              <div>
                <button className="login100-form-btn" onClick={handleVerifyOTP}>Verify OTP</button>
              </div>
            </>
          ) : (
            <>
              <div>
                <label>User Name:</label>
                <input
                  className="input100"
                  type="text"
                  placeholder="Enter your name"
                  name="userName"
                  value={userData.userName}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Phone Number:</label>
                <input
                  className="input100"
                  type="text"
                  placeholder="Enter your MobileNumber"
                  name="phoneNumber"
                  value={userData.phoneNumber}
                  onChange={handleChange}
                />
              </div>
              <button className="login100-form-btn" onClick={handleSendOTP} disabled={userData.loading}>
                {userData.loading ? 'Sending...' : 'Send OTP'}
              </button>
              

            </>
          )}

        </div>
      </div>
    </div>
  );
};

export default Login;

